@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@400;700&family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');
@import './mixins.scss';

body {
  margin: 0;
  padding: 0;
  background: #f8f8f8;
  color: $grey-color;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  font-family: 'Poppins', sans-serif !important;
}

svg {
  pointer-events: none;
}

a {
  text-decoration: none;
  color: $primary-color;

  &:hover {
    color: $primary-color;
    opacity: 0.6;
  }
}

.container {
  padding-left: 16px;
  padding-right: 16px;

  @include flex;
}

.flex-row {
  flex-wrap: wrap;
  gap: 16px;
  @include flex;

  & > div {
    flex: 1;
    min-width: 200px;
  }
}

.ant-layout {
  background: #f8f8f8;

  .ant-layout-content {
    overflow: hidden;
  }

  .ant-layout-sider {
    background-color: #fff;
    @include box-shadow(0px 0px 10px 0px rgba(130, 134, 139, 0.2));

    .ant-layout-sider-children {
      flex-direction: column;
      @include flex;
      @include justify-content(space-between);
    }

    &.collapsed {
      .ant-layout-sider-zero-width-trigger {
        background-color: #dbecd5;
      }
    }
  }

  .ant-layout-sider-trigger {
    background-color: #fff;
  }

  .ant-layout-sider-zero-width-trigger {
    position: absolute;
    z-index: 2;
    top: 20px;
    left: 190px;
    background: transparent;
    width: 24px;
    height: 24px;
    @include border-radius(4px);

    .anticon-bars {
      background-image: url(../src/svgs/menu/chevron-left-double.svg);
      width: 24px;
      height: 24px;
    }

    svg {
      display: none;
    }

    &:hover {
      background-color: #dbecd5;
    }
  }

  .ant-layout-sider-collapsed {
    .ant-layout-sider-zero-width-trigger {
      left: 12px;
      transform: rotate(180deg);
    }
  }

  .ant-layout-header {
    background-color: #fff;
    z-index: 1;
    padding-left: 16px;
    padding-right: 16px;
    @include flex;
    @include justify-content(flex-end);

    .fake-element,
    .logo-top-header {
      display: none;
    }

    .fake-element {
      button {
        margin: 0;
        padding: 0;
        border: none;
        line-height: 0;
        margin-top: 20px;
        border-radius: 4px;

        &:hover {
          color: $grey-color;
          background: #dbecd5;
        }
      }
    }
  }

  .ant-layout-sider-children {
    .logo-section {
      width: 140px;
      padding-top: 16px;
      padding-left: 16px;

      & > div {
        min-height: 0;
      }

      @include flex;
      @include align-items(center);
      @include justify-content(flex-start);
    }
  }

  @media (max-width: 991px) {
    .ant-layout-sider-children {
      .logo-section {
        display: none;
      }
    }

    .ant-layout-header {
      @include justify-content(space-between);

      .fake-element {
        display: block;
        width: 100px;
      }

      .logo-top-header {
        display: block;
        width: 170px;

        @include flex;
      }
    }
  }

  @media (max-width: 575px) {
    .ant-layout-header {
      .fake-element {
        width: 35px;
      }
    }
  }
}

.header-info-dropdown {
  min-width: 200px !important;

  button {
    width: 100%;
    text-align: left;
    color: $primary-color !important;
    border-color: $primary-color !important;
  }

  ul.ant-dropdown-menu {
    padding: 16px 0;

    li.ant-dropdown-menu-item {
      &:hover {
        background: transparent;
      }

      .info-section {
        margin-bottom: 12px;
      }

      span.ant-dropdown-menu-title-content {
        p {
          margin: 0;

          &.fullname {
            font-weight: 500;
            font-size: 14px;
          }

          &.type {
            font-size: 12px;
          }
        }

        a {
          gap: 8px;
          @include flex;
          @include align-items(center);
        }
      }

      .logout-btn {
        text-align: center;
      }
    }
  }
}

.loading-screen {
  width: 100vw;
  height: 100vh;

  @include flex;
  @include justify-content(center);
  @include align-items(center);
}

.ant-btn {
  padding: 9px 12px;
  font-style: normal;
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  height: auto !important;
  @include border-radius(8px);

  span + .anticon {
    margin-right: 0;
    margin-left: 10px;
  }
}

.ant-notification-notice-message {
  margin-bottom: 0 !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.ant-layout-header {
  padding: 0;
  background-color: #005670;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 64px !important;
  z-index: 999;
  @include box-shadow(0px 0px 0px 0px #82868b4d);

  .logo {
    padding: 0 10px;

    img {
      width: 180px;
      height: 40px;
    }

    span {
      margin-left: 10px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      padding: 4px 7px;
      background-color: #fff;
      color: #1e88e5;
      border-radius: 11px;
    }
  }

  .account-info {
    @include flex;
    @include align-items(center);

    .avatar {
      width: 32px;
      height: 32px;
      margin-left: 10px;
      @include flex;

      img {
        width: 100%;
        border-radius: 50%;
      }
    }

    .info {
      margin: auto 0;
      padding: 10px;
      line-height: 16px;
      font-size: 12px;
      color: #212121;

      .fullname {
        font-size: 14px;
        font-weight: 600;
      }

      .info-dropdown {
        button {
          width: 100%;
        }
      }
    }

    .ant-badge {
      margin: auto 10px;

      .anticon {
        width: 40px;
        display: initial;

        svg {
          height: 24px;
        }
      }
    }
  }
}

.content-wrap {
  background-color: #fff;
  padding: 24px;

  @include box-shadow(0px 0px 5px 0px #82868b4d);
  @include border-radius(8px);
}

.form-container {
  .ant-form-item {
    margin-bottom: 16px;

    .ant-form-item-label {
      min-width: 156px;
      text-align: left;
      padding: 0;
      @include flex;

      & > label {
        color: $grey-color;
        font-weight: 400;
        font-size: 12px;
        font-style: normal;
        line-height: 16px;
        margin-bottom: 4px;
      }
    }
  }

  .form-header {
    .title {
      text-transform: uppercase;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      position: relative;
      color: #005670;

      &:before {
        content: '';
        width: 4px;
        height: 100%;
        background-color: #005670;
        position: absolute;
        top: 0;
        left: -16px;
      }
    }
  }

  h2.form-title {
    margin-bottom: 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: $primary-color;
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $primary-color;
      position: absolute;
      left: 0;
      bottom: -4px;
    }
  }

  .form-content {
    margin-top: 24px;

    .ant-form-item-row {
      .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content {
        @include flex;
      }
    }

    .add-fields {
      .ant-btn-dashed.ant-btn-block {
        width: auto;
        margin-top: 0;
      }
    }

    .btn-remove-section {
      margin-bottom: 16px;

      .ant-btn {
        padding: 4px 40px;
      }
    }

    .ant-btn-dashed.ant-btn-block {
      margin-bottom: 16px;
      @include flex;
      @include justify-content(center);
      @include align-items(center);

      &.btn-remove {
        border-color: $danger-color;
        color: $danger-color;
      }
    }

    .ant-form-item-required {
      &::after {
        display: inline-block;
        margin-inline-end: 4px;
        color: $grey-color;
        font-size: 14px;
        line-height: 1;
        content: '*';
        visibility: visible !important;
      }

      &::before {
        display: none !important;
      }
    }
  }

  .form-footer {
    margin-top: 24px;
    @include flex;
    @include justify-content(flex-end);

    button,
    a {
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }

    a > button {
      &:first-child {
        margin-right: 0;
      }
    }
  }

  .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .ant-row .ant-col {
    padding-left: 0 !important;
    padding-right: 0 !important;

    @media (max-width: 767px) {
      flex: 0;
    }
  }

  .fields-inline {
    @include flex;
    @include justify-content(space-between);

    .ant-form-item {
      width: 50%;

      &:last-child {
        margin-left: 24px;
      }
    }

    .ant-picker {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .ant-row {
      flex-flow: column;

      .ant-form-item-label > label {
        margin-bottom: 4px;
      }
    }
  }
}

.ant-form-item-with-help.password-input {
  .ant-form-item-explain {
    div,
    .ant-form-item-explain-error,
    .ant-form-item-explain-success {
      font-size: 10px;
      min-height: 12px;
      position: absolute;
      top: 32px;
      left: 0;
    }
  }
}

.ant-form-item-explain {
  div,
  .ant-form-item-explain-error,
  .ant-form-item-explain-success {
    font-size: 10px;
    min-height: 12px;
    position: absolute;
    left: 0;
  }
}

.page-content-header {
  margin-top: 16px;
  @include flex;
  @include justify-content(space-between);
  @include align-items(center);

  .page-content-header-actions {
    @include flex;

    button {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.details-container {
  h2.details-title {
    margin-bottom: 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: $primary-color;
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $primary-color;
      position: absolute;
      left: 0;
      bottom: -4px;
    }

    &.collapse-closed {
      margin-top: 0;
      margin-bottom: 0;
      &::after {
        display: none;
      }
    }
  }

  .details-content-wrap {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    .item {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      .label {
        margin-bottom: 4px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .value {
        font-family: 'Oxygen', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
}

.ant-tag {
  padding: 2px 8px;
  margin-right: 4px;
  border-radius: 17px;
  border: 1px solid #e3e3e3;
  background: #f0f0f0;
  font-family: 'Oxygen', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;

  &:last-child {
    margin-right: 0;
  }
}

.page-content-footer {
  margin-top: 24px;
  @include flex;
  @include align-items(center);
  @include justify-content(flex-end);

  button,
  a {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.page-container {
  padding: 16px;

  .page-header {
    margin-top: 16px;
    margin-bottom: 24px;
    flex-wrap: wrap;
    @include flex;
    @include justify-content(space-between);
    @include align-items(center);

    .ant-breadcrumb {
      span {
        text-transform: uppercase;
      }
    }
  }

  .page-header-action-left {
    @include flex;
    @include align-items(center);
    @include justify-content(flex-start);
  }

  .page-header-actions {
    @include flex;

    button:not(:last-child),
    a:not(:last-child) {
      margin-right: 12px;
    }
  }

  .page-content {
    padding: 16px;
    background: #ffffff;
    margin: 24px auto;
    @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.08));
    @include border-radius(2px);

    &.list {
      width: 100%;
    }
  }

  @media (max-width: 1199px) {
    .page-content {
      width: 100%;
    }
  }

  @media (max-width: 479px) {
    .page-content .details-container .item {
      @include align-items(flex-start);
      flex-direction: column;

      label {
        margin-bottom: 16px !important;
      }
    }
  }
}

.ant-modal-content {
  padding: 0 !important;

  .ant-modal-header {
    margin-bottom: 0px;
  }

  .ant-modal-body {
    padding: 12px 24px 24px 24px;

    .form-header {
      .title {
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        position: relative;
        color: $primary-color;

        &:before {
          content: '';
          width: 4px;
          height: 100%;
          background-color: $primary-color;
          position: absolute;
          top: 0;
          left: -16px;
        }
      }
    }

    .form-content {
      .ant-form-item {
        flex-flow: column;
      }
    }
  }
}

.ant-modal-confirm-confirm {
  .ant-modal-content {
    min-width: 375px;
    padding: 40px 24px;

    .ant-modal-body {
      padding: 24px;
    }

    .ant-modal-confirm-body {
      @include flex;

      .ant-modal-confirm-content {
        margin-top: 0;
        margin-left: 12px;
      }
    }

    .ant-modal-confirm-body span.anticon.anticon-exclamation-circle {
      color: $danger-color;
      margin-inline-end: 0;
    }

    .ant-modal-confirm-btns {
      margin-top: 24px;
      width: 100%;
      @include flex;
      @include justify-content(center);

      button {
        min-width: 100px;

        &:first-child {
          background-color: #fff;
          color: $primary-color;
          border: 1px solid $primary-color;
        }

        &.ant-btn-primary {
          margin-left: 16px;
          background-color: $primary-color;
          color: #fff;
          border: 1px solid $primary-color;
        }
      }
    }
  }
}

table {
  thead.ant-table-thead th,
  thead.ant-table-thead tr > td.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
    padding: 10px 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    flex: none;
    background: #f0f0f0 !important;
    border: none;
    text-transform: uppercase;
    color: $grey-color;
    border-bottom: none !important;

    &:before {
      display: none;
    }

    span.ant-table-filter-column-title {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      flex: none;
      padding: 0;
    }

    .ant-table-filter-column {
      padding: 17px 16px;
      @include flex;
      @include align-items(center);
    }

    .ant-table-filter-trigger-container {
      position: unset;
      margin-left: 5px;

      &:hover {
        background: #fff;
      }
    }

    .ant-table-filter-trigger {
      text-align: center;
      @include border-radius(2px);

      .anticon {
        position: unset;
        color: $primary-color;
        -webkit-transform: none;
        transform: none;
      }
    }
  }

  td {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    background: #fff;
  }

  tbody.ant-table-tbody {
    tr td {
      padding: 12px 16px;
      color: $grey-color;

      span.action {
        @include flex;
        @include justify-content(center);
        @include align-items(center);

        & > button,
        & > a {
          padding-left: 8px;
          padding-right: 8px;

          & > button {
            padding: 6px 0;
          }
        }

        .ant-dropdown-button {
          button {
            padding: 0 6px 0 6px;
            border: none;
            background-color: transparent;
            @include transform(rotate(90deg));

            &:first-child {
              display: none;
            }

            span.anticon.anticon-ellipsis {
              height: 16px;
            }
          }
        }
      }

      .table-action-icon {
        button {
          margin-right: 8px;
          padding: 4px 8px;

          &:last-child {
            margin-right: 0;
          }

          & > div {
            margin-left: 0;
          }

          svg {
            margin-left: 0;
          }
        }
      }

      .foreign-currency-td {
        @include flex;
        @include align-items(center);

        & > div {
          margin-right: 12px;

          & > div {
            @include flex;
          }
        }
      }

      .sms-transaction-details {
        .details-type {
          font-size: 12px;
          line-height: 16px;
          font-weight: 600;
          text-transform: capitalize;
        }

        .details-account-no {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
        }
      }

      .table-image {
        width: 52px;
        height: 52px;
        border-radius: 4px;
        border: 1px solid #e3e3e3;
        background: #f8f8f8;

        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
      }

      .table-avatar-image {
        width: 52px;
        height: 52px;
        border-radius: 64px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 64px;
        }
      }
    }

    tr:last-child {
      td {
        padding-bottom: 12px;
      }
    }

    .btn-download {
      position: relative;
      min-width: 100%;
      min-height: 32px;
      @include justify-content(center);
      @include align-items(center);

      & > div {
        margin-left: 0px;
      }

      svg {
        width: 16px;
        height: 30px;
      }
    }
  }
}

.table-action-menu {
  .ant-dropdown-menu {
    @include border-radius(4px);

    .btn-danger {
      border: none !important;
      background-color: transparent;
      color: $danger-color !important;
    }
  }
}

.ant-input,
.ant-select-selector,
.ant-picker,
.ant-input-number {
  font-size: 14px !important;
  border: 1px solid $border-color;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  min-width: 0;
  color: $grey-color;
  transition: all 0.3s;
  padding: 8px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  background: #fff;
}

.ant-select-single .ant-select-selector {
  // height: 40px;
  // padding: 0px 8px;

  .ant-select-selection-item {
    line-height: 24px;
  }
}

.ant-input-number {
  padding: 0;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: $primary-50-color;
  color: $grey-color;
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: $grey-color;
}

.ant-select-outlined:hover .ant-select-selector {
  border-color: $primary-color !important;
}

.ant-input:not[textarea],
.ant-select-selector,
.ant-picker,
.ant-input-number {
  height: 40px !important;
}

.ant-select-single,
.ant-select.ant-select-show-arrow {
  height: 40px !important;
}

.ant-input:not([type='password']),
.ant-input-affix-wrapper {
  min-height: 38px;

  &:focus,
  &:hover {
    border-color: $primary-color;
    border-inline-end-width: 1px;
    outline: 0;
  }
}

.ant-input:not([type='password']).ant-input-disabled {
  &:focus,
  &:hover {
    border-color: $border-color;
    border-inline-end-width: 1px;
    outline: 0;
  }
}

.ant-input-affix-wrapper {
  padding-top: 0;
  padding-bottom: 0;
}

.ant-picker-outlined,
.ant-input-number {
  &:focus-within,
  &:hover,
  &:active {
    border-color: $primary-color;
  }
}

span.ant-input-affix-wrapper.ant-input-outlined {
  &:focus-within {
    @include box-shadow(none !important);
    border-color: #64aa4b !important;
  }

  &.ant-input-disabled {
    &:focus-within {
      @include box-shadow(none !important);
      border-color: $border-color !important;
    }
  }
}

.filter-form-container {
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-input {
    min-height: 30px;
    font-family: 'Oxygen', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .ant-select-single {
    min-width: 180px;
    min-height: 40px;
    font-family: 'Oxygen', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    &.ant-select-outlined {
      margin-bottom: 16px;

      .ant-select-selector {
        border-color: $primary-color;
      }

      &:hover {
        .ant-select-selector {
          border-color: $primary-color !important;
        }
      }
    }
  }

  .form-container {
    @include flex;
    @include align-items(center);
    flex-wrap: wrap;
    gap: 12px;

    .form-content {
      margin-top: 0;
      flex-wrap: wrap;
      @include flex;
      @include align-items(flex-start);

      .ant-picker {
        width: 100%;
        min-height: 38px;
      }

      .ant-form-item,
      .ant-select {
        min-width: 200px;

        &:first-child {
          margin-left: 0 !important;
        }
      }

      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
      .ant-select-multiple .ant-select-selector {
        height: 40px;
        padding: 0px 8px;
        overflow-y: auto;
      }
    }

    .form-footer {
      margin-top: 0;
      margin-left: 0;

      button {
        margin-left: 0 !important;
        margin-bottom: 16px;
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &.expand-filter {
    .form-content {
      justify-content: space-between;
      width: 100%;

      .ant-form-item {
        flex-grow: 1;
      }
    }

    .form-footer {
      width: 100%;

      button {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 375px) {
    .form-container {
      width: 100%;

      .form-content {
        width: 100%;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;
        gap: 12px;

        .ant-form-item {
          width: 100%;
          min-width: auto;
          margin-right: 0 !important;
        }
      }

      .form-footer {
        width: 100%;
      }
    }
  }
}

.btn-table-action-primary {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: $primary-color;
  border: none;
  background: none;
  @include box-shadow(none);

  @include flex;

  & > div {
    margin-left: 8px;
  }

  &.has-icon {
    padding: 0;
  }
}

.react-tel-input {
  .special-label {
    display: none;
  }

  .form-control {
    height: 40px;
    width: 100%;
    border: 1px solid $border-color;
    border-radius: 8px;
    font-size: 14px;
    padding: 10px 10px 10px 50px;

    &:hover,
    &:focus {
      border: 1px solid $border-color;
      box-shadow: none;
    }
  }

  .flag-dropdown {
    .react-tel-input .selected-flag {
      outline: none;
      position: relative;
      width: 52px;
      height: 100%;
      padding: 0 0 0 11px;
      border-radius: 3px 0 0 3px;
    }

    .country-list {
      border: 1px solid #e0e0e0;
      border-radius: 2px;
      margin-top: 1px;

      .search {
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 10px;

        .search-box {
          width: calc(100% - 20px);
          height: 32px;

          &:hover,
          &:focus {
            border: 1px solid #e0e0e0;
            box-shadow: none;
          }
        }
      }
    }
  }
}

.ant-form-item.ant-form-item-has-error {
  .react-tel-input {
    .form-control {
      border: 1px solid #ff4d4f;
    }
  }

  .ant-checkbox .ant-checkbox-inner {
    border: 1px solid #b13939 !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border: 1px solid #64aa4b !important;
  }
}

.btn-table-action-danger {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: #f44336;

  @include flex;

  & > div {
    margin-left: 8px;
  }

  &:hover {
    background: none !important;
  }
}

.pagination-container {
  @include flex;
  @include justify-content(flex-end);
  @include align-items(center);
}

.ant-pagination-options {
  vertical-align: baseline;
  .ant-select-arrow {
    margin-top: -8px;
  }
}

.ant-spin-nested-loading {
  width: 100%;
}

.ant-checkbox-wrapper {
  .ant-checkbox-input {
    width: 20px !important;
    height: 20px !important;
  }
  &:hover {
    .ant-checkbox-inner {
      border-color: $primary-color !important;
    }
  }

  &.ant-checkbox-wrapper-disabled {
    .ant-checkbox-inner {
      background: #c4c4c4;
    }

    &:hover {
      .ant-checkbox-inner {
        border-color: #d9d9d9 !important;
      }
    }
  }

  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: $primary-color !important;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: $primary-color;
      border-color: $primary-color;
    }

    &:hover {
      .ant-checkbox-inner {
        border-color: $primary-color;
      }
    }
  }
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: $primary-color !important;
}

.ant-pagination {
  .ant-pagination-item-active {
    border-color: $primary-color;

    a {
      color: $primary-color;
    }

    &:hover {
      border-color: $primary-color;

      a {
        color: $primary-color;
      }
    }
  }

  .ant-select-selector {
    height: 32px !important;
  }
}

.more-item-number {
  color: #898989;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.card-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  padding-bottom: 2rem;

  .card-item {
    padding: 1rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: flex-start;
    width: 230px;
    border: none;
    text-align: left;
    @include border-radius(8px);
    @include box-shadow(0px 0px 5px 0px rgba(130, 134, 139, 0.3));

    & > button {
      border: none;
      background: none;
    }

    @media (max-width: 1223px) {
      width: 100%;
    }

    @media (max-width: 759px) {
      width: 100%;
    }
  }

  @media (max-width: 1223px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
  }

  @media (max-width: 759px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }

  @media (max-width: 479px) {
    display: block;
    .card-item {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.page-404-403-container {
  width: 100%;
  height: 100vh;
  @include flex;
  @include align-items(center);
  @include justify-content(center);

  .wraper {
    max-width: 429px;
    color: #898989;
    text-align: center;

    img {
      width: 100%;
      height: 236px;
    }

    h2 {
      margin-top: 24px;
      margin-bottom: 0;
      font-size: 34px;
      font-style: normal;
      font-weight: 500;
      line-height: 42px;
      letter-spacing: -0.68px;
    }

    p {
      margin-top: 12px;
      margin-bottom: 0;
      text-align: center;
      font-family: 'Oxygen', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  @media (max-width: 575px) {
    .wraper {
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;

      img {
        width: 275px;
        height: 165px;
      }

      h2 {
        margin-top: 16px;
        font-size: 24px;
        line-height: 28px;
      }

      p {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

.password-requirement-section {
  background: #f8f8f8;
  padding: 12px;
  margin-bottom: 16px;

  @include border-radius(4px);

  p {
    margin-top: 0;
    margin-bottom: 4px;
    color: #898989;
    font-size: 10px;
    line-height: 14px;

    & > span.weak {
      color: $danger-color;
    }

    & > span.strong {
      color: #0a7a08;
    }
  }

  div.requirement-item {
    color: #4b4b4b;
    font-size: 10px;
    line-height: 14px;

    @include flex;
    @include align-items(center);

    svg {
      margin-right: 5px;
    }

    &.fulfilled {
      color: #0a7a08;
    }
  }
}

.context-modal-custom-container {
  .ant-modal-body {
    position: relative;
    padding: 16px !important;
    overflow: hidden;
    @include border-radius(8px);
    @include box-shadow(0px 0px 10px 0px rgba(130, 134, 139, 0.2));

    &:before {
      content: '';
      width: 6px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .ant-modal-content {
    overflow: hidden;
  }

  .ant-modal-confirm-title {
    color: $grey-color;
    font-family: 'Oxygen', sans-serif;
    font-weight: 700;
  }

  .ant-modal-confirm-content {
    font-family: 'Oxygen', sans-serif;
  }

  .ant-modal-confirm-body {
    gap: 16px;

    & > svg {
      margin-top: 4px;
      min-width: 16px;
    }
  }

  &.ant-modal-confirm-error {
    .ant-modal-body {
      background: #fcecea;

      &:before {
        background: #b13939;
      }
    }
  }

  &.ant-modal-confirm-success {
    .ant-modal-body {
      background: #eafbe7;

      &:before {
        background: #0a7a08;
      }
    }
  }
}

.ant-dropdown.create-allocation-dropdown {
  .ant-dropdown-menu {
    padding: 12px;
  }

  .ant-dropdown-menu-item {
    padding: 9px 12px;
    color: $grey-color;
    font-family: 'Oxygen', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    @include border-radius(4px);

    &:hover {
      background: $primary-50-color;
    }

    span.ant-dropdown-menu-title-content {
      a,
      button {
        background: transparent;
        gap: 12px;
        @include flex;
        @include align-items(center);
      }
    }
  }
}

.ant-radio-wrapper {
  &:hover {
    .ant-radio-inner {
      border-color: $primary-color !important;
    }
  }

  .ant-radio {
    &:hover {
      .ant-radio-inner {
        border-color: $primary-color !important;
      }
    }

    &.ant-radio-checked {
      &:hover {
        .ant-radio-inner {
          border-color: $primary-color !important;
        }
      }

      &::after {
        border-color: $primary-color !important;
      }

      .ant-radio-inner {
        border-color: $primary-color !important;
        background-color: $primary-color;
      }
    }

    &.ant-radio-disabled {
      .ant-radio-inner {
        background-color: #f5f5f5;
        border-color: #d9d9d9 !important;
      }

      &.ant-radio-checked {
        .ant-radio-inner {
          background-color: #d9d9d9;
          border-color: #d9d9d9 !important;
        }
      }
    }
  }
}

.danger-text {
  margin: 0;
  color: $danger-color !important;
}

.flex-3-column {
  flex-wrap: wrap;
  gap: 16px;
  @include flex;

  & > div {
    flex: 0 1 calc(33.33% - 16px);
    min-width: 200px;
  }
}

#serial-code-component {
  margin: 0.4rem 0 1.2rem 0;
  color: #224a13;
  font-family: 'Oxygen', sans-serif;
  font-size: 1.4rem;
  line-height: 2rem;
}

.app-tabs {
  .ant-tabs-nav {
    &::before {
      border-bottom: 1px solid #dad9d9;
    }
  }
  .ant-tabs-tab {
    .tab-title {
      color: #898989;
      gap: 4px;
      font-weight: 400;
      @include flex;
      @include align-items(center);
    }

    &.ant-tabs-tab-active {
      .tab-title {
        color: $primary-800-color;
        font-weight: 600;
      }
    }
  }

  .ant-tabs-ink-bar {
    background: $primary-color;
  }
}

.ant-space-item {
  line-height: 0;
}

.truncate-3-lines {
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow-wrap: break-word;
}

.truncate-2-lines {
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow-wrap: break-word;
}

.common-drawer-container {
  .ant-drawer-body {
    padding-top: 0;
  }

  .ant-drawer-header {
    border-bottom: none !important;
    padding: 32px 24px;
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    button.ant-drawer-close {
      margin-right: 0;
    }

    .ant-drawer-title {
      line-height: 0;
    }
  }
}

.flex-2-column {
  gap: 8px;
  flex-wrap: wrap;
  @include flex;

  & > div {
    flex: 1;
  }
}

.hardware-footer-section {
  margin-left: -16px;
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #fff;
  border-bottom: 1px solid #dad9d9;
  z-index: 1;
  @include flex;
  @include justify-content(center);
  @include box-shadow(0px 0px 10px 0px rgba(130, 134, 139, 0.2));

  & > div {
    width: 672px;
    padding: 24px;
    gap: 10px;
    margin-left: -210px;
    @include flex;

    button {
      &:first-child:not(.btn-primary) {
        flex: 0 0 auto;
        padding: 12px;
        min-width: 170px;
        border: 1px solid $danger-color;
        background: #fff;
        color: $danger-color;
        @include border-radius(8px);
      }

      &:first-child.btn-secondary {
        flex: 0 0 auto;
        padding: 12px;
        min-width: 170px;
        border: 1px solid $primary-color;
        background: #fff;
        color: $primary-color;
        @include border-radius(8px);
      }

      &:last-child {
        flex: 1;
        padding: 12px;
        border: 1px solid $primary-color;
        color: #fff !important;
        background: $primary-color !important;
      }

      &:disabled {
        border: 1px solid #f5f5f5;
        background: #f5f5f5 !important;

        &:hover {
          opacity: 1;
          background: #64aa4b;
        }
      }
    }
  }

  @media (max-width: 991px) {
    & > div {
      margin-left: -5px;
    }
  }

  @media (max-width: 767px) {
    & > div {
      width: 100%;
      button {
        &:first-child:not(.btn-primary) {
          flex: 1;
          min-width: auto;
        }

        &:first-child.btn-secondary {
          flex: 1;
          min-width: auto;
        }

        &:last-child {
          flex: 1;
          min-width: auto;
        }
      }
    }
  }
}

.drawer-has-title-width-full {
  .ant-drawer-header-title {
    @apply tw-w-full;
  }
}

.ant-dropdown.filter-drowdown {
  min-width: 220px !important;
  background: #fff;
  padding: 16px;

  @include border-radius(8px);
  @include box-shadow(0px 0px 5px 0px rgba(130, 134, 139, 0.3));
}

.flex-row {
  flex-wrap: wrap;
  gap: 16px;
  @include flex;

  & > div.item {
    flex: 0 1 calc(33.33% - 16px);
    min-width: 200px;
  }
}

.ant-notification-notice.AppNotification {
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 8px;

  &:before {
    content: '';
    width: 6px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .ant-notification-notice-icon {
    width: 16px;
    height: 16px;
  }
  .ant-notification-notice-wrapper .ant-notification-notice-close {
    top: 6px;
    inset-inline-end: 6px;
  }

  .ant-notification-notice-description {
    margin-inline-start: 32px !important;
  }

  &.ant-notification-notice-error {
    background: #fcecea;
    &:before {
      background-color: #b13939;
    }
  }
}

.ant-message-notice-wrapper {
  .ant-message-notice-content {
    padding: 16px 24px !important;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      width: 6px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    .anticon.anticon-check-circle {
      margin-inline-end: 16px;
    }
  }

  .ant-message-notice-error .ant-message-notice-content {
    background: #fcecea;

    .anticon.anticon-check-circle svg {
      fill: #b13939;
    }

    &:before {
      background-color: #b13939;
    }
  }

  .ant-message-notice-info .ant-message-notice-content {
    @apply tw-bg-info-100;

    .anticon.anticon-check-circle svg {
      fill: #3f7990;
    }

    &:before {
      @apply tw-bg-info-500;
    }
  }

  .ant-message-notice-success .ant-message-notice-content {
    background: #eafbe7;

    .anticon.anticon-check-circle svg {
      fill: #0a7a08;
    }

    &:before {
      @apply tw-bg-success-500;
    }
  }
}

.anticon-info-circle svg {
  fill: #3f7990;
}

.scan-workflow-tab.ant-tabs {
  .ant-tabs-nav-list {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid $border-color;
    background: #fff;

    @include flex;
    @include justify-content(space-between);

    .ant-tabs-tab {
      flex: 1;
      margin: 0;
      padding: 6px 12px;
      color: $secondary-text;
      font-size: 14px;
      line-height: 20px;
      font-family: 'Poppins', sans-serif;
      @include justify-content(center);

      &.ant-tabs-tab-active {
        background-color: $primary-50-color;
        @include border-radius(8px);

        .ant-tabs-tab-btn {
          color: #437132;

          span {
            color: $grey-color;
          }
        }
      }

      .ant-tabs-tab-btn {
        flex-direction: column;
        @include flex;
        @include align-items(center);

        svg {
          margin-bottom: 8px;
        }
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }
}

.ant-tooltip .ant-tooltip-inner {
  min-height: auto !important;
}

.ant-spin {
  max-height: 100%;
}
