@import '../../mixins.scss';

.btn-danger {
  @include flex;
  @include justify-content(center);
  @include align-items(center);
  background-color: $danger-color;
  border: 1px solid $danger-color;
  color: #fff !important;
  @include border-radius(2px);

  &.no-label {
    padding: 0;
    background-color: transparent !important;
    border: none !important;

    svg {
      margin-left: 0;
    }

    &:hover {
      border: none !important;
    }
  }

  &:hover,
  &.ant-btn:focus {
    background-color: $danger-color;
    border: 1px solid $danger-color;
    opacity: 0.8;
  }
  &.no-label:hover,
  &.no-label.ant-btn:focus {
    background-color: none !important;
    border: none !important;
    opacity: 0.8;
  }

  svg {
    margin-right: 4px;
  }
}

.danger-button-confirm-modal {
  &.horizontal {
    .ant-modal-confirm-btns {
      @apply tw-flex tw-flex-col-reverse tw-gap-3;
    }
  }

  &.danger {
    .ant-modal-confirm-btns {
      .ant-btn-primary {
        background: #b13939 !important;
        margin-left: 0 !important;
        border-color: #b13939 !important;
      }

      .ant-btn-default {
        border-color: #dad9d9 !important;
        color: #191919 !important;
      }
    }
  }
}
